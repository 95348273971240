import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BusinessCard from "./pages/business-card/BusinessCard";

import DasRedirect from "./pages/das-redirect/DasRedirect";
import DeviceNotFound from "./pages/das-redirect/DeviceNotFound";

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<div />} />
          <Route path="/redirect" element={<DasRedirect />} />
          <Route path="/redirect/:serialNumber" element={<DasRedirect />} />
          <Route path="/redirect/device-not-found" element={<DeviceNotFound />} />
          <Route path="/business-card/:id" element={<BusinessCard />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
