import styled from "styled-components";

export const DeviceNotFoundStyle = styled.section`
  display: flex;
  flex-direction: column;
  width: 75%;
  height: 100vh;
  justify-content: center;
  align-items: center;

  h2 {
    margin: 0px 0 0px 0;
  }
`;
