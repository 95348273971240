import React, { useState, useEffect } from "react";
import VCard from "vcard-creator";
import {
  Container,
  Nav,
  Header,
  NameSection,
  Photo,
  DetailsContainer,
  Item,
  ItemValue,
  QR,
  SocialIcons,
  Divider
} from "./styled/BusinessCard";

import downloadIcon from "./img/download.svg";
import instagramIcon from "./img/instagram.svg";
import twitterIcon from "./img/twitter.svg";
import linkedinIcon from "./img/linkedin.svg";
import whatsappIcon from "./img/whatsapp.svg";
import { useNavigate, useParams } from "react-router-dom";

import mockedData from "./mocked-data/people";

const BusinessCard = () => {
  const [qrData, setQrData] = useState("");
  const [downloadData, setDownloadData] = useState("");

  const params = useParams();
  const navigate = useNavigate();

  const userId = params.id || "";

  const user = mockedData.find((element) => element.id === userId);

  function download(filename: string, text: string) {
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/x-vcard;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  useEffect(() => {
    if (user) {
      const { name, surname, title, company, email, phone, address } = user;

      const [street, worktown, workpostcode, country] = address.split(";");
      const vCard = new VCard();
      vCard
        .addName(surname, name, "", "", "")
        .addCompany(company)
        .addJobtitle(title)
        .addEmail(email)
        .addPhoneNumber(phone, "WORK")
        .addAddress("", "", street, worktown, "", workpostcode, country);

      setQrData(vCard.toString().replace(/\n/g, "%0A"));
      setDownloadData(vCard.buildVCard());
    } else navigate("/");
  }, [navigate, params.id, user]);

  if (user) {
    return (
      <Container>
        <Nav>
          <img src={user.companyLogo} alt="logo" />
          <img
            style={{ cursor: "pointer" }}
            src={downloadIcon}
            alt="download"
            onClick={() =>
              download(`${user.name}-${user.surname}`, downloadData)
            }
          />
        </Nav>
        <Divider />
        <Header>
          <NameSection>
            <h2>
              {user.name} {user.surname}
            </h2>
            <p>
              {user.title} • {user.company}
            </p>
          </NameSection>
          <Photo>
            <img src={user.photo} alt="user" />
          </Photo>
        </Header>
        <Divider />
        <DetailsContainer>
          <Item>
            <p>Email</p>
            <ItemValue>{user.email}</ItemValue>
          </Item>
          <Divider />
          <Item>
            <p>Phone number</p>
            <ItemValue>{user.phone}</ItemValue>
          </Item>
          <Divider />
          <Item>
            <p>Address</p>

            <DetailsContainer>
              {user.address.split(";").map((e, i) => (
                <ItemValue key={i}>{e}</ItemValue>
              ))}
            </DetailsContainer>
          </Item>
          <Divider />
          <Item>
            <p>Social</p>
            <SocialIcons>
              {user?.social?.instagram && (
                <a
                  href={user.social.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={instagramIcon} alt="instagram" />
                </a>
              )}
              {user?.social?.linkedin && (
                <a
                  href={user.social.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkedinIcon} alt="linkedin" />
                </a>
              )}
              {user?.social?.twitter && (
                <a
                  href={user.social.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={twitterIcon} alt="twitter" />
                </a>
              )}
              {user?.social?.whatsapp && user.phone && (
                <a
                  href={user.social.whatsapp + user.phone.replace(/\s+/g, "")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={whatsappIcon} alt="whatsapp" />
                </a>
              )}
            </SocialIcons>
          </Item>
          <Divider />
          {qrData && (
            <QR
              alt="qr"
              src={`https://chart.googleapis.com/chart?cht=qr&chs=200x200&chl=${qrData}`}
            />
          )}
        </DetailsContainer>
      </Container>
    );
  }
  return null;
};

export default BusinessCard;
