import { SpinnerContainer, StyledSpinner } from "./SpinnerStyle";

function Spinner() {
  return (
    <SpinnerContainer>
      <StyledSpinner />
    </SpinnerContainer>
  );
}

export default Spinner;
