import axios from "axios";
import { ENV } from "../env"
import { DasCheckDeviceResponse } from "./Models";

export class DasApi {
  public async dasCheckDevice(
    serialNumber: string,
    cryptogram?: string | null
  ): Promise<DasCheckDeviceResponse> {
    return new Promise(async function (resolve, reject) {

      try {
        const { data } = await axios.get<DasCheckDeviceResponse>(
          `${getDasUrl(ENV)}/campaign/${serialNumber}?c=${cryptogram}`
        );
        resolve(data);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log("error message: ", error.message);
          reject(error.message);
          return error.message;
        } else {
          console.log("unexpected error: ", error);
          reject("An unexpected error occurred");
        }
      }
    });
  }
}

export default new DasApi();


const getDasUrl = (env: string) => {
  switch (env) {
    case "development":
      return "https://www.dev-manage-mii.uk/api/das";
    case "staging":
      return "https://www.staging-manage-mii.uk/api/das";
    case "demo":
      return "https://www.demo-manage-mii.uk/api/das";
    case "production":
      return "https://www.manage-mii.uk/api/das";
    default:
      return "https://www.manage-mii.uk/api/das";
  }
};