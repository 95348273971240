import { BusinessCard } from "../../../api/Models";
import companyLogo from "./images/logo.svg";

const people = [
  {
    id: "colin_tanner",
    name: "Colin",
    surname: "Tanner",
    title: "CTO",
    photo: require("./images/Colin-Tanner.jpg"),
    company: "Digiseq",
    companyLogo,
    email: "colin.tanner@digiseq.co.uk",
    phone: "+44(0)7790 337778",
    address: "69 Wilson Street;London;EC2A2BB;UK",
    social: {
      instagram: "https://www.instagram.com/digiseq_ltd",
      linkedin: "https://www.linkedin.com/company/digiseq",
      twitter: "https://twitter.com/digiseqUK",
      whatsapp: "https://wa.me/"
    }
  },
  {
    id: "alex_komarov",
    name: "Alex",
    surname: "Komarov",
    title: "Head of Products",
    photo: require("./images/Alex-Komarov.jpg"),
    company: "Digiseq",
    companyLogo,
    email: "alex.komarov@digiseq.co.uk",
    phone: "+44(0)7856 438198",
    address: "69 Wilson Street;London;EC2A2BB;UK",
    social: {
      instagram: "https://www.instagram.com/digiseq_ltd",
      linkedin: "https://www.linkedin.com/company/digiseq",
      twitter: "https://twitter.com/digiseqUK",
      whatsapp: "https://wa.me/"
    }
  },
  {
    name: "Andy",
    surname: "Ramsden",
    title: "Business Development Consultant",
    photo: require("./images/Andy-Ramsden.jpg"),
    company: "Digiseq",
    companyLogo,
    email: "andy.ramsden@digiseq.co.uk",
    phone: "44(0)7525 687168",
    address: "69 Wilson Street;London;EC2A2BB;UK",
    social: {
      instagram: "https://www.instagram.com/digiseq_ltd",
      linkedin: "https://www.linkedin.com/company/digiseq",
      twitter: "https://twitter.com/digiseqUK",
      whatsapp: "https://wa.me/"
    }
  },
  {
    id: "nigel_palmer",
    name: "Nigel",
    surname: "Palmer",
    title: "Sales Executive",
    photo: require("./images/Nigel-Palmer.jpg"),
    company: "Digiseq",
    companyLogo,
    email: "nigel.palmer@digiseq.co.uk",
    phone: "+44(0)7969 026928",
    address: "69 Wilson Street;London;EC2A2BB;UK",
    social: {
      instagram: "https://www.instagram.com/digiseq_ltd",
      linkedin: "https://www.linkedin.com/company/digiseq",
      twitter: "https://twitter.com/digiseqUK",
      whatsapp: "https://wa.me/"
    }
  },
  {
    id: "iain_case",
    name: "Iain",
    surname: "Case",
    title: "Solution Integration Manager",
    photo: require("./images/Iain-Case.jpg"),
    company: "Digiseq",
    companyLogo,
    email: "iain.case@digiseq.co.uk",
    phone: "+44(0)7525 478250",
    address: "69 Wilson Street;London;EC2A2BB;UK",
    social: {
      instagram: "https://www.instagram.com/digiseq_ltd",
      linkedin: "https://www.linkedin.com/company/digiseq",
      twitter: "https://twitter.com/digiseqUK",
      whatsapp: "https://wa.me/"
    }
  },
  {
    id: "melanie_koestler",
    name: "Melanie",
    surname: "Koestler",
    title: "Head of Innovation & Growth",
    photo: require("./images/Melanie-Koestler.jpg"),
    company: "Digiseq",
    companyLogo,
    email: "melanie.koestler@digiseq.co.uk",
    phone: "+49(0)1768 1944230",
    address: "69 Wilson Street;London;EC2A2BB;UK",
    social: {
      instagram: "https://www.instagram.com/digiseq_ltd",
      linkedin: "https://www.linkedin.com/company/digiseq",
      twitter: "https://twitter.com/digiseqUK",
      whatsapp: "https://wa.me/"
    }
  },
  {
    id: "lenny_davies",
    name: "Lenny",
    surname: "Davies",
    title: "Product Marketing Manager",
    photo: require("./images/Lenny-Davies.jpg"),
    company: "Digiseq",
    companyLogo,
    email: "lenny.davies@digiseq.co.uk",
    phone: "+44(0)7437 014210",
    address: "69 Wilson Street;London;EC2A2BB;UK",
    social: {
      instagram: "https://www.instagram.com/digiseq_ltd",
      linkedin: "https://www.linkedin.com/company/digiseq",
      twitter: "https://twitter.com/digiseqUK",
      whatsapp: "https://wa.me/"
    }
  },
  {
    id: "ido_kariti",
    name: "Ido",
    surname: "Kariti",
    title: "CEO",
    photo: require("./images/ido-kariti.jpg"),
    company: "Digiseq",
    companyLogo,
    email: "ido.kariti@digiseq.co.uk",
    phone: "+972 5424 76035",
    address: "69 Wilson Street;London;EC2A2BB;UK",
    social: {
      instagram: "",
      linkedin: "https://www.linkedin.com/in/ido-kariti/",
      twitter: "",
      whatsapp: "https://wa.me/"
    }
  },
  {
    id: "terrie_smith",
    name: "Terrie",
    surname: "Smith",
    title: "Ambassador",
    photo: require("./images/Terrie-Smith.jpg"),
    company: "Digiseq",
    companyLogo,
    email: "terrie.smith@digiseq.co.uk",
    phone: "+44(0)7850 05683",
    address: "69 Wilson Street;London;EC2A2BB;UK",
    social: {
      instagram: "https://www.instagram.com/digiseq_ltd",
      linkedin: "https://www.linkedin.com/company/digiseq",
      twitter: "https://twitter.com/digiseqUK",
      whatsapp: "https://wa.me/"
    }
  },
  {
    id: "brad_blake",
    name: "Brad",
    surname: "Blake",
    title: "CEO",
    photo: require("./images/Brad-Blake.png"),
    company: "Network B",
    companyLogo,
    email: "brad@networkb.uk",
    phone: "+44 7814 043138",
    address: "25 Emmer Green;Luton;LU2 8UH;UK",
    social: {
      instagram: "https://www.instagram.com",
      linkedin: "https://www.linkedin.com/company/networkb/",
      twitter: "https://twitter.com",
      whatsapp: "https://wa.me/"
    }
  },
  {
    id: "jelle_tekampe",
    name: "Jelle te",
    surname: "Kampe",
    title: "Founder",
    photo: require("./images/Jelle-Kampe.png"),
    company: "Astari",
    companyLogo,
    email: "Jelle@astariwearables.com",
    phone: "+31620443326",
    address: "Roggedreef 43;Doetinchem;7006LW;Netherlands",
    social: {
      instagram: "https://www.instagram.com/astariwearables/ ",
      linkedin: "https://www.linkedin.com/company/astariwearables/",
      twitter: "https://twitter.com",
      whatsapp: "https://wa.me/31620443326"
    }
  },
  {
    id: "lawrence_hill",
    name: "Lawrence",
    surname: "Hill",
    title: "",
    photo: require("./images/lawrence_hill.png"),
    company: "Lawrence Hill Music",
    companyLogo,
    email: "Lawrence@proud2present.co.uk",
    phone: "+44 7814 043138",
    address: "",
    social: {
      instagram: "https://www.instagram.com/lawrencehillmusic/",
      linkedin: "",
      twitter: "https://twitter.com",
      whatsapp: "https://wa.me/"
    }
  }
] as BusinessCard[];

export default people;
