import { useEffect } from "react";
import { StyledMain } from "../../styled/StyledMain";
import { MainContainer } from "../../styled/StyledMainContainer";
import { DeviceNotFoundStyle } from "./styles/DeviceNotFoundStyles";

const DeviceNotFound = () => {
  function sleep(time: number) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  useEffect(() => {
    async function validate() {
      await sleep(3000);
      window.location.replace("https://www.manage-mii.co.uk");
    }
    validate();
  }, []);

  return (
    <StyledMain>
      <MainContainer>
        <DeviceNotFoundStyle>
          <h2>
            Your device is not valid. Please try again or try a different device
          </h2>
        </DeviceNotFoundStyle>
      </MainContainer>
    </StyledMain>
  );
};

export default DeviceNotFound;
