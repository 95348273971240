import styled from "styled-components";

interface Props {
}

export const StyledMain = styled.main<Props>`
  background-color: "#fff";
  grid-area: main;
  justify-self: center;
  width: 100%;
`;
